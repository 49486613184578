<template>
	<div>
		<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark"
			@click.stop="row.toggleDetails" class="mr-1 mt-1">
			<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
			<i class="fa fa-eye" v-else></i>
		</b-button>

		<b-button v-show="row.item.status === repairStatus.DRAFT && !isViewer" size="sm" v-b-modal.edit-repair
			v-b-tooltip.hover.top="'Edit Repair Request'" variant="warning" @click.stop="updateRepair(row.item)"
			class="mr-1 mt-1">
			<i class="fa fa-pencil"></i>
		</b-button>

		<b-button v-show="row.item.status === repairStatus.DRAFT && !isViewer" size="sm" v-b-modal.start-repair
			v-b-tooltip.hover.top="'Start Repair Request'" variant="primary" @click.stop="updateRepair(row.item)"
			class="mr-1 mt-1">
			<img :src="icons.wrenchScrewdriver" alt width="16" />
		</b-button>

		<b-button v-show="row.item.status == repairStatus.IN_REPAIR && !this.isViewer" size="sm" variant="success"
			v-b-modal.mark-as-done-repair v-b-tooltip.hover.top="'Mark Repair Request as Done'" class="mr-1 mt-1"
			@click.stop="updateRepair(row.item)">
			<i class="fa fa-check"></i>
		</b-button>

		<b-button
			v-show="(row.item.status == repairStatus.DRAFT || row.item.status == repairStatus.IN_REPAIR) && !this.isViewer"
			size="sm" v-b-modal.cancel-repair v-b-tooltip.hover.top="'Cancel Repair Request'" variant="danger"
			@click.stop="updateRepair(row.item)" class="mr-1 mt-1">
			<i class="fa fa-ban"></i>
		</b-button>
	</div>
</template>

<script>
// Component
import wrenchScrewdriver from '@/assets/images/icons/wrench-screwdriver.svg';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';

export default {
	name: 'repair-row-actions',
	props: {
		row: {
			type: Object,
			required: true
		},
		isViewer: {
			type: Boolean,
			required: true
		},
	},
	data() {
		return {
			repairStatus: config.repairStatus,
			icons: {
				wrenchScrewdriver,
			}
		}
	},
	methods: {
		updateRepair(item) {
			this.$store.commit('SET_CURR_SET_REPAIRS', item);
			EventBus.$emit('onUpdateRepair', item);
		}
	}
};
</script>
