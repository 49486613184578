// Others
import config from '@/config/env-constants';

function getDefaultRepairObj() {
	return {
		repairTaskId: '',
		status: config.repairStatus.DRAFT,
		assetType: null,
		assetCode: null,
		reasonForRepair: '',
	};
}

function cleanupFields(repair) {
    let repairObj = { ...repair };

    delete repairObj['assetCode'];
    delete repairObj['_showDetails'];

    return repairObj;
}

export const RepairUtil = {
	getDefaultRepairObj,
	cleanupFields
};
