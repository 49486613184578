<template>
    <div class="ml-4">
        <Loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
        <div class="timeline d-flex">
            <div class="d-flex flex-column align-items-center">
                <img :src="renderHorizontalIcon(1)" alt width="75" />
                <small>Repair Request</small>
                <small>Started</small>
                <small class="text-muted">{{ renderDate(1) }}</small>
            </div>
            <div class="d-flex flex-column align-items-center">
                <img :src="renderHorizontalIcon(2)" alt width="75" />
                <small>Repair Request</small>
                <small>{{ row.item.status !== repairStatus.CANCELLED ? 'Marked as Done' : 'Cancelled' }}</small>
                <small class="text-muted">{{ renderDate(2) }}</small>
            </div>
        </div>
        <div class="mt-4" v-if="row.item.status === repairStatus.DRAFT">
            <i>Repair request is currently in draft status.</i>
        </div>
        <template v-else>
            <div class="container mx-0 mt-4">
                <div class="timeline-vertical d-flex flex-column">
                    <template v-for="(log, index) in changeLogs">
                        <template v-if="log.new.status === repairStatus.IN_REPAIR">
                            <div v-bind:key="index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(null, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{ showFormattedDateTime(log.new.dateCreated)
                                }}</small>
                                <small>Repair request status updated to
                                    <b-badge variant="success">{{ repairStatus.IN_REPAIR }}</b-badge>
                                </small>
                            </div>
                            <div v-bind:key="'a' + index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(log.new.status, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{ showFormattedDateTime(log.new.dateCreated)
                                }}</small>
                                <div class="d-flex flex-column">
                                    <strong>Repair request started: {{ log.new.repairTaskId }}</strong>
                                    <small>Created by {{ log.new.createdBy }}</small>
                                </div>
                            </div>
                        </template>
                        <template v-if="log.new.status === repairStatus.CANCELLED">
                            <div v-bind:key="index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(null, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{ showFormattedDateTime(log.new.dateUpdated)
                                }}</small>
                                <small>Repair request status updated to
                                    <b-badge variant="danger">{{ repairStatus.CANCELLED }}</b-badge>
                                </small>
                            </div>
                            <div v-bind:key="'a' + index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(log.new.status, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{ showFormattedDateTime(log.new.dateUpdated)
                                }}</small>
                                <div class="d-flex flex-column">
                                    <strong>Repair request cancelled</strong>
                                    <small>Cancelled by {{ log.new.updatedBy }}</small>
                                </div>
                            </div>
                        </template>
                        <template v-if="log.new.status === repairStatus.DONE">
                            <div v-bind:key="index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(null, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{ showFormattedDateTime(log.new.dateUpdated)
                                }}</small>
                                <small>Repair request status updated to
                                    <b-badge variant="primary">{{ repairStatus.DONE }}</b-badge>
                                </small>
                            </div>
                            <div v-bind:key="'a' + index" class="d-flex flex-row align-items-start">
                                <img :src="renderVerticalIcon(log.new.status, index)" alt width="25" />
                                <small class="text-muted flex-shrink-0">{{ showFormattedDateTime(log.new.dateUpdated)
                                }}</small>
                                <div class="d-flex flex-column">
                                    <strong>Repair request marked as done</strong>
                                    <small>Done by {{ log.new.updatedBy }}</small>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
            </div>

            <span v-if="seeMoreChangeLogs.length > 3" class="truncate-text" @click="toggleSeeState">
                <template v-if="!seeLess">
                    <a>See Less</a>
                </template>
                <template v-if="seeLess">
                    <a>See More</a>
                </template>
            </span>
        </template>
    </div>
</template>

<script>
// Database
import auditTrailLogsDAO from '@/database/auditTrailLogs';

// Component
import cancel from '@/assets/images/timelines/cancel.svg';
import documentFill from '@/assets/images/timelines/document-fill.svg';
import documentOutline from '@/assets/images/timelines/document-outline.svg';
import doneFill from '@/assets/images/timelines/done-fill.svg';
import doneOutline from '@/assets/images/timelines/done-outline.svg';

import documentGray from '@/assets/images/timelines/document-gray.svg';
import doneGray from '@/assets/images/timelines/done-gray.svg';
import cancelGray from '@/assets/images/timelines/cancel-gray.svg';
import circleGray from '@/assets/images/timelines/circle-gray.svg';
import circleOrange from '@/assets/images/timelines/circle-orange.svg';

// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import config from '@/config/env-constants';
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import _ from "lodash";

export default {
    name: 'repair-timeline-details-view',
    components: {
        Loading
    },
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            icons: {
                cancel,
                documentFill,
                documentOutline,
                doneFill,
                doneOutline,
                documentGray,
                doneGray,
                cancelGray,
                circleGray,
                circleOrange
            },
            repairStatus: config.repairStatus,

            changeLogs: [],
            seeMoreChangeLogs: [],
            seeLessChangeLogs: [],
            repairTaskId: '',

            loggedUser: this.$store.getters.loggedUser,

            seeLess: true,
            isLoading: false
        };
    },
    mounted() {
        setTimeout(async () => {
            this.repairTaskId = this.row.item.id;

            await this.retrieveChangeLog(this.repairTaskId);
        }, config.timeout);
    },
    watch: {
        seeLess() {
            if (this.seeLess) {
                this.changeLogs = [...this.seeLessChangeLogs];
            } else {
                this.changeLogs = [...this.seeMoreChangeLogs];
            }
        },
        'row.item.status': {
            async row() {
                await this.retrieveChangeLog(this.disposalRequestId);
            },
            deep: true
        }
    },
    methods: {
        async retrieveChangeLog(repairTaskId) {
            this.isLoading = true;

            this.changeLogs = [];

            if (repairTaskId) {
                const param = {
                    collection: 'assetRepairTasks',
                    id: repairTaskId
                }

                const resultObj = await auditTrailLogsDAO.getAuditTrailLogs(param);
                const results = Object.values(resultObj);


                const draft = _.maxBy(_.filter(results, result => result.new.status === config.repairStatus.DRAFT),
                    dr => dr.dateCreated);
                const inRepair = _.maxBy(_.filter(results, result => result.new.status === config.repairStatus.IN_REPAIR
                    && result.new.status !== result.old.status), dr => dr.dateCreated);
                const cancelled = _.maxBy(_.filter(results, result => result.new.status === config.repairStatus.CANCELLED
                    && result.new.status !== result.old.status), dr => dr.dateCreated);
                const done = _.maxBy(_.filter(results, result => result.new.status === config.repairStatus.DONE
                    && result.new.status !== result.old.status), dr => dr.dateCreated);

                this.seeMoreChangeLogs.push(draft);

                if (inRepair) {
                    this.seeMoreChangeLogs.push(inRepair);
                }

                if (cancelled) {
                    this.seeMoreChangeLogs.push(cancelled);
                }

                if (done) {
                    this.seeMoreChangeLogs.push(done);
                }

                this.seeMoreChangeLogs = _.orderBy(this.seeMoreChangeLogs, ['dateCreated'], ['desc']);
                this.seeLessChangeLogs = [...this.seeMoreChangeLogs];

                if (this.seeLessChangeLogs.length > 2) {
                    this.seeLessChangeLogs.splice(2);
                }

                this.changeLogs = [...this.seeLessChangeLogs];

                this.isLoading = false;
            }
        }, showFormattedDateTime(date) {
            return DateUtil.getFormattedShortDateWithTime(date);
        }, renderHorizontalIcon(position) {
            switch (position) {
                case 1:
                    return this.row.item.status === config.repairStatus.DRAFT
                        ? this.icons.documentOutline
                        : this.icons.documentFill;
                default:
                    return this.row.item.status === config.repairStatus.CANCELLED
                        ? this.icons.cancel
                        : this.row.item.status !== config.repairStatus.DONE
                            ? this.icons.doneOutline
                            : this.icons.doneFill;
            }
        }, renderVerticalIcon(status, index) {
            switch (status) {
                case config.repairStatus.IN_REPAIR:
                    return this.icons.documentGray;
                case config.repairStatus.CANCELLED:
                    return this.icons.cancelGray;
                case config.repairStatus.DONE:
                    return this.icons.doneGray;
                default:
                    return index === 0 ? this.icons.circleOrange : this.icons.circleGray;
            }
        },
        renderDate(position) {
            switch (position) {
                case 1:
                    return this.row.item.status === config.repairStatus.DRAFT
                        ? "-"
                        : this.showFormattedDateTime(this.row.item.dateCreated)
                default:
                    return this.row.item.status !== config.repairStatus.CANCELLED && this.row.item.status !== config.repairStatus.DONE
                        ? '-'
                        : this.showFormattedDateTime(this.row.item.dateUpdated);
            }
        },
        toggleSeeState() {
            this.seeLess = !this.seeLess;
        }
    }
}
</script>
<style scoped>
.timeline {
    justify-content: space-between;
    position: relative;
    width: 80%;
    max-width: 370px;
    z-index: 1;
}

/* Horizontal line */
.timeline::before {
    content: "";
    position: absolute;
    top: 25%;
    left: 10%;
    width: 80%;
    height: 4px;
    background-color: #f18f01;
    z-index: -1;
}

.timeline-vertical {
    gap: 30px;
    position: relative;
    z-index: 1;
}

/* Vertical line */
.timeline-vertical::before {
    content: "";
    position: absolute;
    width: 4px;
    background-color: #dadada;
    top: 20px;
    /* Starts below the first circle */
    height: calc(100% - 40px);
    /* Stops above the last circle */
    left: 13px;
    transform: translateX(-50%);
    z-index: -1;
}

.timeline-vertical>div {
    gap: 20px;
}

.timeline-vertical div:first-child>* {
    color: #f18f01 !important;
}

.truncate-text {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #f18f01;
}
</style>
